var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('a-form',{attrs:{"form":_vm.form,"colon":false,"label-col":{ span: 6 },"wrapper-col":{ span: 16 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"资产编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              {
                initialValue: _vm.detail.code,
                rules: [{ required: true, message: '请输入' }],
              },
            ]),expression:"[\n              'code',\n              {\n                initialValue: detail.code,\n                rules: [{ required: true, message: '请输入' }],\n              },\n            ]"}],attrs:{"disabled":true}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"资产名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                initialValue: _vm.detail.name,
                rules: [{ required: true, message: '请输入' }],
              },
            ]),expression:"[\n              'name',\n              {\n                initialValue: detail.name,\n                rules: [{ required: true, message: '请输入' }],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"品牌"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'brand',
              {
                initialValue: _vm.detail.brand,
              },
            ]),expression:"[\n              'brand',\n              {\n                initialValue: detail.brand,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"规格型号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'specification',
              {
                initialValue: _vm.detail.specification,
              },
            ]),expression:"[\n              'specification',\n              {\n                initialValue: detail.specification,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"单位"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'unit',
              {
                initialValue: _vm.detail.unit,
              },
            ]),expression:"[\n              'unit',\n              {\n                initialValue: detail.unit,\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"单价(元)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'price',
              {
                initialValue: _vm.detail.price,
                rules: [{ required: true, message: '请输入' }],
              },
            ]),expression:"[\n              'price',\n              {\n                initialValue: detail.price,\n                rules: [{ required: true, message: '请输入' }],\n              },\n            ]"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"保管员"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'chargerId',
              {
                initialValue: _vm.detail.chargerId,
                rules: [
                  {
                    required: true,
                    message: '请选择',
                  },
                ],
              },
            ]),expression:"[\n              'chargerId',\n              {\n                initialValue: detail.chargerId,\n                rules: [\n                  {\n                    required: true,\n                    message: '请选择',\n                  },\n                ],\n              },\n            ]"}]},_vm._l((_vm.userList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loading}},[_vm._v("提交")])],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header"},[_c('img',{staticClass:"bg",attrs:{"src":require("@/assets/page-header-bg.png"),"alt":""}}),_c('span',{staticClass:"text"},[_vm._v("采购申请")])])
}]

export { render, staticRenderFns }